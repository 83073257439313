/* Shadow Constants */
:root {
  --shadow-color: 0deg 0% 10%;
  --shadow-elevation-low:
    0px 1px 1.2px hsl(var(--shadow-color) / 0.37),
    0.1px 4.1px 5.1px -1.7px hsl(var(--shadow-color) / 0.55);
  --shadow-elevation-medium:
    0px 0px 20.2px hsl(var(--shadow-color) / 213.77),
    0.3px 20.6px 25.6px 9.3px hsl(var(--shadow-color) / 0.5);
  --shadow-elevation-high:
    0px 1px 1.2px hsl(var(--shadow-color) / 0.65),
    0.4px 27.1px 33.7px -0.8px hsl(var(--shadow-color) / 0.85),
    1.7px 106px 132px -1.7px hsl(var(--shadow-color) / 1);
  --animation-header: 250ms ease-in-out;
  --animation-border: 200ms ease-in-out;
}

.headerContainer {
  position: sticky;
  z-index: 9999;
  top: 0;
  font-family: PolymathText;
  background-color: none;
  background-color: var(--footer-background);
}




.pageHeader {

  height: 3.5em;
  top: 0;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1em;
  margin-left: 1em;
  padding: 0.5em 0em;
  position: sticky;
  backdrop-filter: blur(10px);

}

.pageHeaderScroll {
  top: 0;
  height: 3em;
  z-index: 999999;
  /* padding: 0.2em 1em; */
  /* margin-left: 2em; */
  /* margin-right: 2em; */
}

.pageHeaderScroll:hover {

  /* transform: scale(1.01); */
  transition: all 200ms ease-in-out;
  /* backdrop-filter: blur(5px); */
}

.headerHeaderLight {
  backdrop-filter: blur(15px);
  display: flex;
  position: sticky;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: black; */
}

/* Header Bottom Border */
.headerBorder,
.headerBorderScroll {
  transition: all var(--animation-header)
}

.headerBorder {
  margin-left: 18px;
  margin-right: 18px;
  position: sticky;
  z-index: 1;
  height: 2px;
  background-color: var(--header-border);
}

.headerBorderScroll {
  position: sticky;
  justify-items: center;
  align-content: center;
  opacity: 0;
}


#toggle {
  transform: translateY(4px);
  cursor: pointer;
}


#toggleComponent {
  display: none;
}


.pageHeaderscroll {

  margin-left: 0em;
  margin-right: 0em;

}

.title {
  color: var(--text-color);
  margin-left: 0em;
  display: flex;
  align-items: center;

}

.titleScroll {
  border: 1.5em;
  color: rgb(93, 92, 92);
}

.switcher {
  display: flex;
  align-self: center;
}

.divLine {
  /* color: gray !important; */
}