/*! Flickity v3.0.0
https://flickity.metafizzy.co
----------------------------- */


.flickity-button {
  z-index: -1;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: fit-content;
  touch-action: pan-y;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.flickity-rtl .flickity-slider {
  left: unset;
  right: 0;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

/* ---- flickity-cell ---- */

.flickity-cell {
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-cell {
  left: unset;
  right: 0;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 75%);
  border: none;
  color: hsl(0, 0%, 20%);
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  color: #19F;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentcolor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  z-index: 1;
  /* above viewport */
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #676767;
  border-radius: 50%;
  opacity: 0.65;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.dot:active {
  color: #19F !important;
}

/* FLickity carousel EXTRA CSS */
.carousel {
  /* margin-top: 2vh; */
  /* max-height: 70vh;   */
  /* overflow: hidden; */
  height: max-content;

}


.carouselItemDescription {
  color: var(--text-color);
  font-weight: 200;
  opacity: 0;
  margin-top: 2px;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.carouselItemContainer:hover .carouselItemDescription {
  opacity: 1;
}



.carousel-image {
  border-radius: 15px;
  width: fit-content;
  width: 70vh;

}

.carouselItemContainer {
  text-align: center;
  padding: 5px;
  font-family: 'PolymathText', sans-serif;

}

.carouselItemDescription {
  font-weight: 200;
  opacity: 1;
  margin-top: 2px;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.carouselItemContainer:hover .carouselItemDescription {
  opacity: 1;
}

.carouselContainer {
  margin-top: 15px;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .carousel-image {
    width: 400px;
  }

  .carouselContainer {
    margin-top: 5vh;
  }


}