.footer {
    font-family: "Polymathtext";
    font-size: 1.2em;

    justify-content: right;
    width: calc(100% - 20px);
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--footer-background);
    backdrop-filter: blur(10px) saturate(180%);

    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    color: var(--text-color);
}

/* Marquee text styling */
.footer span {

    font-weight: bold;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
    .footer {
        font-size: 1em;
        width: calc(100% - 30px);
    }
}

/* Large desktop */
@media screen and (min-width: 1500px) {
    .footer {
        font-size: 1.5rem;
    }
}