nav {
  /* width: fit-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 5px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

nav li {
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  border-radius: 25%;

}

/* nav li:hover {
  margin: 5px;
  padding: 5px; 
  border-radius: 25%;
  background-color: rgba(158, 158, 158, 0.399);
} */


.activeIcon {
  background-color: rgb(57, 57, 57);
  border-radius: 25%;
}

.activeIconLight {
  background-color: rgb(239, 239, 239);
  border-radius: 25%;
}

.componentTab>svg {
  scale: 1;
  pointer-events: none;

}

.grid {
  /* scale: 1.3 !important; */
}