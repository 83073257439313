.grid-container {
  display: grid;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.grid-item {
  position: relative;
  text-align: center;
}

.gridImage {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: border 300ms ease-in-out, transform 300ms ease-in-out;
}

.gridImage:hover {
  border: 1px solid white;
  transform: scale(1.05);
}

.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  /* Glass-like blur */
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  pointer-events: none;
  /* Avoid interfering with hover on the image */
}

.overlay-text {
  color: black;
  font-family: "Polymath", sans-serif;
  font-size: 1rem;
  text-align: center;
  padding: 1em;
}

.overlay-container:hover .overlay {
  opacity: 1;
  pointer-events: auto;
}