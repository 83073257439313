/* Refactored media queries */
@media screen and (max-width: 1499px) {

    .title .switch,
    .divLine {
        font-size: 1.5rem;
    }
}

/* mobile */
@media screen and (min-width: 1px) and (max-width: 600px) {

    .headerBorder {
        display: none;
    }

    .title {
        font-size: 1.7rem;
        text-align: center;
        line-height: 1px;
    }


    .switcher {
        padding-bottom: 1em;
        padding-top: 0px;

    }

    .switch,
    .divLine {
        font-size: 1.3rem;
    }

    .pageHeader {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
        padding: 0px;
        margin: 0px;

        .about {
            margin-top: 0;
        }

    }

    /* MOBILE MEDIA .pageHeaderScroll */
    .pageHeaderScroll {

        /* margin-right: 1em; */
        /* margin-left: 1em; */

        z-index: 2;
        transition: padding 300ms ease-in-out, margin-left 500ms ease-in-out, margin-right 500ms ease-in-out, margin-top 500ms ease-in-out, justify-content 300ms ease-in-out, border-radius 300ms ease-in-out, transform 300ms ease-in-out, box-shadow 300ms ease-in-out;
    }

    .pageHeaderScroll>.switcher {

        /* display: none; */
    }
}

/* @media screen and (min-width: 501px) and (max-width: 799px) {
    /* Add styles for this range if needed */

@media screen and (min-width: 601px) and (max-width: 1499px) {
    .title {
        font-size: 1.5rem;
    }

    .switcher {
        font-size: 1.5rem;
    }
}



@media screen and (min-width: 1500px) {


    .title,
    .switch,
    .divLine {
        font-size: var(--size-desktop-wide)
    }
}