.errorContainer {
    scale: 1.5;
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h3 {
    margin-bottom: 0px;
}