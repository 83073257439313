.about {
  display: flex;
  flex-direction: column;

  font-family: PolymathText;
  color: #282828;
}

@media (min-width: 750px) {
  .about {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-items: center;
  }
}

.about-text {

  max-width: 600px;
  width: 100%;
  color: var(--text-color);
  padding-top: 0px;
  margin-top: 0px;
}

.about-text-light {

  color: rgb(0, 0, 0);
}


h3 {
  font-size: 2rem;

}

.paragraph {
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.6;
}

.paragraph:nth-of-type(2) {
  color: #666;
}

.paragraph:nth-of-type(2) {
  color: #888;
}

.paragraph:nth-of-type(3) {
  color: #aaa;
}

.cvLink {
  color: var(--link-color);
  text-decoration: none;
  font-weight: bold;
}

.cvLink:hover {
  text-decoration: underline;
}

.aboutPageBreak {
  margin: 1.5rem 0;
  border: 0;
  height: 1px;
  background: #ddd;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  width: 450px;

  border: px solid #ddd;
  transition: transform 300ms ease-in-out;
}

.profileImage:hover {
  transform: scale(1.01);
}


@media (min-width: 1024px) {
  h3 {
    font-size: 2.5rem;
  }

  .paragraph {
    font-size: 1.2rem;
  }
}