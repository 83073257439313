/* layout */
.table {
  margin-left: 1em;
  margin-top: 1em;
  text-align: left;
  width: 100%;
  color: var(--text-color);
}

/* top row borders */
.table tr.topRow {
  border-top: 1px solid white;
}

.table img {
  width: 35px;
  height: auto;
  box-sizing: border-box;
  /* Include border in width and height calculations */
  border: 1px solid transparent;
  /* Set default border to transparent */
  transition: border 0.2s ease-in-out;
}

.table img:hover {
  border: 1px solid white;
}

td:nth-child(1),
th:nth-child(1) {
  width: 0px;
  padding-right: 5px;
}

/* table heading style */
th {
  border-bottom: 1em solid transparent;
  font-weight: 600;
  text-decoration: underline;
}

/* animation */
td:hover {
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

/* font */
.tableDescription {
  font-weight: lighter;
}

/* Target table data in columns 2 and 3 (excluding the first and last column) on hover */
.table td:nth-child(n+2):not(:last-child) {
  position: relative;
}

/* Add a transparent border to the content, and set the hover styles for the border */
.table td:nth-child(n+2):not(:last-child)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: border 0.2s ease-in-out;
}

/* Set the border color on hover */
.table td:nth-child(n+2):not(:last-child):hover::before {
  border: 1px solid rgb(110, 110, 110);
}

.tableHeadingLinkIcon {
  position: relative;
  scale: 0.9;
  left: 7px;
  top: 3px;
}