html,
#root {
  margin: 0;
}

/* Global Theme Variables */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --header-background: #f0f0f0;
  --header-border: #d0d0d0;
  --link-color: #007acc;
  --footer-background: #b8b8b8a3;
  --footer-text-color: #000000;
  --size-mobile: 1.7rem;
  --size-desktop: 1.5rem;
  --size-desktop-wide: 2rem;
}

[data-theme="dark"] {
  --background-color: #000000;
  --text-color: #ffffff;
  --header-background: #222222;
  --header-border: #ffffff;
  --link-color: #66b3ff;
  --footer-background: rgba(115, 115, 115, 0.2);
  --footer-text-color: #ffffff;
}

@font-face {
  font-family: 'PolymathText';
  src: url('./Fonts/PolymathDemo-Regular.woff') format('woff'),
    url('./Fonts/PolymathDemo-Regular.woff2') format('woff');
  font-weight: 100 900;
  /* Adjust based on font weights available */
  font-style: normal;
  /* Adjust if italics are included */
}

body {
  font-family: 'PolymathText', sans-serif;
}

body {
  margin: 0;
}

main {
  display: flex;

  flex-direction: column;
}



a {
  color: var(--blue)
}


.about {
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
}

.profileImage {
  width: fit-content;
  width: 50vh;
  border: 1px solid transparent;
  margin-left: 1em;
  filter: saturate(80%);
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.profileImage:hover {
  /* width | style | color */
  border: 1px solid white;
  filter: saturate(100%);
}

.cvLink {
  color: rgb(0, 191, 255);
}



/* MEDIA QUERIES */
@media screen and (max-width: 1499px) {}

/* Mobile / phone  */
@media screen and (min-width: 1px) and (max-width: 600px) {
  .footer {
    justify-content: center;
    font-size: 0.9em;
  }

  .carousel img {
    width: 80%;
  }

  .componentTab {
    font-size: larger !important;
  }

  .about {
    font-size: 1.3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

  }

  .profileImage {
    width: 40vh;
    margin-left: 0em;
  }

}


/* END MEDIA QUERIES */

body {
  background-color: var(--background-color);

  color: white;
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 450;
  font-style: normal;
}





#gameImage {
  mix-blend-mode: multiply;
  filter: contrast(1);
}